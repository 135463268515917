// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDoDFG4XRA6pMCkeWwYjoABjxbN2fg5EBc",
  authDomain: "ru-novel-images.firebaseapp.com",
  projectId: "ru-novel-images",
  storageBucket: "ru-novel-images.appspot.com",
  messagingSenderId: "690261004979",
  appId: "1:690261004979:web:af048e201834bc913d16c6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//vicotor email config storage bucket:


// import { initializeApp } from "firebase/app";


// // Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyCyvJCJjPAMI2ypsG6xKy2usnaETR1bEuI",
//   authDomain: "ru-novel-images-95d51.firebaseapp.com",
//   projectId: "ru-novel-images-95d51",
//   storageBucket: "ru-novel-images-95d51.appspot.com",
//   messagingSenderId: "87740691636",
//   appId: "1:87740691636:web:6677fcb62b276a9707d3e1"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
export default app;