

import React, { useState } from 'react';

 import ResetPassword from '../components/ResetPassword';


const ResetPasswordPage = () => {

  return (
    <div>
      
      <ResetPassword/>
       
      </div>
    
  );
};

export default ResetPasswordPage;