

import React from 'react';
import SignupPage from '../components/SignUpForm';


const SignUp = () => {


  

  return (
    <div>
      
       <SignupPage/>
       
      </div>
    
  );
};

export default SignUp;
