

import React, { useState } from 'react';
 import ForgotPassword from '../components/ForgotPassword';


const ForgotPasswordPage = () => {

  return (
    <div>
      
      <ForgotPassword/>
       
      </div>
    
  );
};

export default ForgotPasswordPage;
