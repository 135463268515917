
// src/pages/HomePage.js
import { useEffect } from 'react';
import React from 'react';

import LoginForm from '../components/LoginForm';

const Login = () => {
 


  return (
    <div>
      
       <LoginForm/>
       
      </div>
    
  );
};

export default Login;
